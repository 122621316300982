const formatWeather = (value) => {
  const sunny = require('assets/img/singleMachine/sunny.png')
  const overcast = require('assets/img/singleMachine/overcast.png')
  const hot = require('assets/img/singleMachine/hot.png')
  const cold = require('assets/img/singleMachine/cold.png')
  const haze = require('assets/img/singleMachine/haze.png')
  const lightrain = require('assets/img/singleMachine/lightrain.png')
  const mediumrain = require('assets/img/singleMachine/mediumrain.png')
  const thunderShower = require('assets/img/singleMachine/thunderShower.png')
  const ranstorm = require('assets/img/singleMachine/ranstorm.png')
  const cloudy = require('assets/img/singleMachine/cloudy.png')
  const snowrain = require('assets/img/singleMachine/snowrain.png')
  const snow = require('assets/img/singleMachine/snow.png')
  const lightwindy = require('assets/img/singleMachine/lightwindy.png')
  const hurricane = require('assets/img/singleMachine/hurricane.png')
  const floatdust = require('assets/img/singleMachine/floatdust.png')
  const sandstorm = require('assets/img/singleMachine/sandstorm.png')
  const tornado = require('assets/img/singleMachine/tornado.png')
  const fog = require('assets/img/singleMachine/fog.png')

  let weather = null
  switch (value) {
    case '晴':
    case '平静':
    case '未知':
      weather = sunny
      break;
    case '阴':
      weather = overcast
      break;
    case '热':
      weather = hot
      break;
    case '冷':
      weather = cold
      break;
    case '霾':
    case '中度霾':
    case '重度霾':
    case '严重霾':
      weather = haze
      break;
    case '毛毛雨/细雨':
    case '小雨':
    case '中雨':
    case '小雨-中雨':
      weather = lightrain
      break;
    case '大雨':
    case '中雨-大雨':
      weather = mediumrain
      break;
    case '阵雨':
    case '雷阵雨':
    case '雷阵雨并伴有冰雹':
    case '强雷阵雨':
      weather = thunderShower
      break;
    case '极端降雨':
    case '暴雨':
    case '大暴雨':
    case '特大暴雨':
    case '大雨-暴雨':
    case '大暴雨-特大暴雨':
      weather = ranstorm
      break;
    case '少云':
    case '晴间多云':
    case '多云':
      weather = cloudy
      break;
    case '雨雪天气':
    case '雨夹雪':
    case '阵雨夹雪':
    case '冻雨':
      weather = snowrain;
      break;
    case '雪':
    case '阵雪':
    case '小雪':
    case '中雪':
    case '大雪':
    case '暴雪':
    case '小雪-中雪':
    case '中雪-大雪':
    case '大雪-暴雪':
      weather = snow
      break;
    case '有风':
    case '微风':
    case '和风':
    case '清风':
    case '强风/劲风':
    case '疾风':
    case '大风':
    case '烈风':
      weather = lightwindy
      break;
    case '风暴':
    case '狂爆风':
    case '飓风':
    case '热带风暴':
      weather = hurricane
      break;
    case '浮尘':
    case '扬沙':
      weather = floatdust
      break;
    case '沙尘暴':
    case '强沙尘暴':
      weather = sandstorm
      break;
    case '龙卷风':
      weather = tornado
      break;
    case '雾':
    case '浓雾':
    case '强浓雾':
    case '轻雾':
    case '大雾':
    case '特强浓雾':
      weather = fog
      break;
    default:
      break;
  }
  return weather
}

const translateMap = (name, that) => {
  switch (name) {
    case '新疆':
    case '青海':
    case '甘肃':
    case '湖北':
    case '吉林':
    case '山东':
      that.top = '9%'
      that.left = '25%'
      break;
    case '内蒙古':
      that.top = '8%'
      that.left = '20%'
      break;
    case '黑龙江':
    case '宁夏':
    case '山西':
    case '陕西':
    case '河北':
    case '北京':
    case '天津':
    case '辽宁':
    case '四川':
    case '重庆':
    case '河南':
    case '安徽':
    case '江苏':
    case '上海':
    case '浙江':
    case '江西':
    case '湖南':
    case '福建':
    case '台湾':
    case '香港':
    case '澳门':
    case '广东':
    case '广西':
    case '云南':
    case '贵州':
    case '海南':
      that.top = '9%'
      that.left = '30%'
      break;
    case '西藏':
      that.top = '9%'
      that.left = '15%'
      break;

    default:
      break;
  }
}
export default {
  formatWeather, translateMap
}
